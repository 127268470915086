<template>
  <div>
    <div
      v-if="description"
      class="copy sm:text-base mb-8"
      v-html="$md.render(description)"
    ></div>
    <div
      v-if="hasItems"
      class="content-cards grid grid-spacing grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
    >
      <ContentCard
        v-for="(item, i) in items"
        :key="i"
        :class="{ 'content-card-link': !!item.link?.to }"
        v-bind="item"
        :tabindex="item.link?.to ? 0 : -1"
        @keydown.enter.prevent="item.link?.to ? goTo(item.link?.to) : undefined"
        @click="item.link?.to ? goTo(item.link?.to) : undefined"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentCards',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasItems() {
      return this.items && Array.isArray(this.items) && this.items.length > 0
    }
  },
  methods: {
    goTo(link) {
      if (!link.startsWith('/')) {
        navigateTo(link, {
          open: {
            external: true,
            target: '_blank'
          }
        })
      } else {
        navigateTo(link, {
          external: false
        })
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
:deep(div.content-card-link) {
  @apply hover:shadow-lg hover:-translate-y-1 transition-all cursor-pointer;
}
</style>
